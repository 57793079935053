import React, { useRef, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';

const SwiperItem = styled.div`
    .swiper-slide__container {
        max-height: ${props => props.theme.scale * props.theme.videoMaxHeight}px;          
        transition: max-height 0.5s;
        overflow: hidden;
        video {
            width: ${props => props.theme.scale * props.theme.videoWidth}px;    
            height: ${props => props.theme.scale * props.theme.videoHeight}px;    
        }
    }
    img {
        width: 100%;
        height: 100%;
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    &:focus {
        outline: none;
    } 
    .slick-slide {
        position: relative;
        width: ${props => props.theme.scale * props.theme.videoWidth}px;
        height: ${props => props.theme.scale * props.theme.videoHeight - 35}px;
        padding-top: ${props => props.theme.scale * 17}px;
        transition: padding 0.5s, opacity 0.5s, height 0.5s;
        overflow: hidden;
        opacity: 0.8;
    }
    .slick-current {
        padding-top: 0;
        opacity: 1;
        height: ${props => props.theme.scale * props.theme.videoHeight}px;
        .swiper-slide__container {            
            max-height: ${props => props.theme.scale * props.theme.videoHeight}px;
        }
    }
`;

const Slider = ({ className }) => {
    const ads = useSelector(state => state.data.videoads);
    const scale = useSelector(state => state.config.scale);
    const layout = useSelector(state => state.config.layout);
    const SliderTime = 10000; // 10 seconds per slide
    const RestartInterval = 60000; // Restart every 60 seconds

    const mySlider = useRef(null);
    let animationFrame = useRef(null);
    let restartTimer = useRef(null);

    // Function to start the slider
    const startSlider = useCallback(() => {
        if (mySlider.current) {
            animationFrame.current = requestAnimationFrame(() => {
                mySlider.current.slickPlay();
            });
        }
    }, []);

    // Function to stop the slider
    const stopSlider = useCallback(() => {
        if (mySlider.current) {
            cancelAnimationFrame(animationFrame.current);
            mySlider.current.slickPause();
        }
    }, []);

    // Function to restart slider every 60 seconds
    const restartSlider = useCallback(() => {
        if (mySlider.current) {
            mySlider.current.slickGoTo(0); // Restart to first slide
            mySlider.current.slickPlay();
        }
    }, []);

    // Page visibility detection (Pause slider when tab is inactive)
    useEffect(() => {
        const handleVisibilityChange = () => {
            if (document.hidden) {
                stopSlider();
                clearInterval(restartTimer.current);
            } else {
                startSlider();
                restartTimer.current = setInterval(restartSlider, RestartInterval);
            }
        };

        document.addEventListener("visibilitychange", handleVisibilityChange);
        restartTimer.current = setInterval(restartSlider, RestartInterval); // Start restart timer

        return () => {
            document.removeEventListener("visibilitychange", handleVisibilityChange);
            clearInterval(restartTimer.current);
        };
    }, [startSlider, stopSlider, restartSlider]);

    let padding;
    switch (layout) {
        case 2:
            padding = 72;
            break;
        default:
            padding = 60;
            break;
    }

    const params = {
        infinite: ads && ads.length > 1,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: ads && ads.length > 1,
        centerPadding: `${padding * scale}px`,
        dots: ads && ads.length > 1,
        autoplay: ads && ads.length > 1,
        autoplaySpeed: SliderTime,
        afterChange: function (index) {
            mySlider.current.slickPause();
            let video = document.querySelector(`[data-index="${index}"] video`);
            if (video) {
                video.play().catch(() => {});
                video.currentTime = 0;
                video.onended = () => {
                    mySlider.current.slickNext();
                };
            } else {
                mySlider.current.slickPlay();
            }
        },
        beforeChange: function (index) {
            let video = document.querySelector(`div[data-index="${index}"] video`);
            if (video) {
                video.currentTime = 0;
                video.pause();
            }
        }
    };

    const handleInit = () => {
        let firstVideo = document.querySelector('div[data-index="0"] video');
        if (firstVideo) {
            firstVideo.play();
            firstVideo.onended = () => {
                mySlider.current.slickNext();
            };
        } else {
            setTimeout(() => {
                if (mySlider.current) {
                    mySlider.current.slickNext();
                }
            }, SliderTime);
        }
    };

    const handleImgOnload = () => {
        setTimeout(() => {
            if (mySlider.current) {
                mySlider.current.slickNext();
            }
        }, SliderTime);
    };

    let slides = [];
    if (ads) {
        slides = ads.map((ad, i) => {
            if (ad.video) {
                return (
                    <SwiperItem key={i}>
                        <div className="swiper-slide__container" id={`video${i}`} isvideo="1" iscurrent="0">
                            <video poster={ad.placeholderurl} muted>
                                <source src={ad.video} type="video/mp4" />
                            </video>
                        </div>
                    </SwiperItem>
                );
            } else {
                return (
                    <SwiperItem key={i}>
                        <img src={ad.image} alt={ad.title} onLoad={i === 0 ? handleImgOnload : null} />
                    </SwiperItem>
                );
            }
        });
    }

    return (
        <React.Fragment>
            {ads ? (
                <StyledSlickSlider ref={mySlider} onInit={handleInit} className={className} {...params}>
                    {slides}
                </StyledSlickSlider>
            ) : (
                <div>Loading...</div>
            )}
        </React.Fragment>
    );
}

export default Slider;
